<template>
  <NbButton
    variant="tertiary"
    @click="generateOverpackLabel()"
    title="Overpack Label"
    :disabled="loading"
  >
    {{
      loading
        ? $t("loading") + "..."
        : $t("overpacksListPage.generateLabelAndInvoice")
    }}
  </NbButton>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import OverpackService from "@/services/OverpackService.js";

const overpackService = new OverpackService();

export default {
  name: "BtnDispatchOverpack",
  components: {
    NbButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    generateOverpackLabel() {
      this.loading = true;
      overpackService
        .generateOverpackLabel(this.id)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);
          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
