<template>
  <NbModal id="modalUpdateMultipleOverpacks">
    <template #header>
      <h4 class="heading-4">
        {{ $t("overpacksListPage.updateFirstMileInformations") }}
      </h4>
    </template>
    <template #body>
      <NbTextInput
        v-model="firstMileInfo.first_mile_carrier_name"
        :error="errors['first_mile_carrier_name']"
        id="first_mile_carrier_name"
        :name="$t('overpacksListPage.carrierName')"
        required
        placeholder="Ex: FedEx"
      />

      <NbTextInput
        v-model="firstMileInfo.first_mile_tracking_number"
        :error="errors['first_mile_tracking_number']"
        id="first_mile_tracking_number"
        :name="$t('trackingNumber')"
        required
        placeholder="AZ1234567890"
      />
    </template>
    <template #footer>
      <div class="d-flex justify-content-end">
        <NbButton variant="secondary" @click="closeModal">{{
          $t("cancel")
        }}</NbButton>
        <NbButton class="ml-2" @click="updateMultipleOverpacks">{{
          $t("update")
        }}</NbButton>
      </div>
    </template>
  </NbModal>
</template>

<script>
import NProgress from "nprogress";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import OverpackService from "@/services/OverpackService.js";

const overpackService = new OverpackService();

export default {
  name: "ModalUpdateFirstMile",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ids: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbModal,
    NbButton,
    NbTextInput,
  },
  watch: {
    show(oldValue, newValue) {
      if (oldValue === newValue) return;

      if (newValue) {
        this.resetForm();
        this.$helpers.openModal("modalUpdateMultipleOverpacks");
        return;
      }

      this.closeModal();
    },
  },
  data() {
    return {
      errors: [],
      firstMileInfo: {
        first_mile_carrier_name: "",
        first_mile_tracking_number: "",
      },
    };
  },
  methods: {
    closeModal() {
      this.$helpers.closeModal("modalUpdateMultipleOverpacks");
      this.$emit("update:show", false);
    },
    resetForm() {
      this.errors = [];
      this.firstMileInfo = {
        first_mile_carrier_name: "",
        first_mile_tracking_number: "",
      };
    },
    updateMultipleOverpacks() {
      const overpacksToUpdate = this.ids;
      this.errors = [];

      overpackService
        .updateManyOverpacks({
          overpack_ids: overpacksToUpdate,
          first_mile_carrier_name: this.firstMileInfo.first_mile_carrier_name,
          first_mile_tracking_number:
            this.firstMileInfo.first_mile_tracking_number,
        })
        .then((response) => {
          const message = response.data.messages[0];
          this.$helpers.toast(message, "success");
          this.$helpers.closeModal("modalUpdateMultipleOverpacks");
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.errors = error.response.data.messages[0];
          } else {
            this.$helpers.toast(
              this.$t("errorMessages.genericError"),
              "danger"
            );
          }

          NProgress.done();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
