<template>
  <NbButton
    @click="dispatchOverpack()"
    :title="$t('overpacksListPage.readyToDispatch')"
    variant="tertiary"
    :disabled="loading"
  >
    {{
      loading ? $t("loading") + "..." : $t("overpacksListPage.readyToDispatch")
    }}
  </NbButton>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import OverpackService from "@/services/OverpackService.js";

const overpackService = new OverpackService();

export default {
  name: "BtnDispatchOverpack",
  components: {
    NbButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    dispatchOverpack() {
      this.loading = true;
      overpackService
        .dispatchOverpack(this.id)
        .then(() => {
          this.$helpers.toast(
            `Overpack ${this.$t("overpacksListPage.readyToDispatch")}`,
            "success"
          );
        })
        .then(() => {
          this.$emit("success");
        })
        .catch((error) => {
          const errors = this.$helpers.extractErrorMessages(error);
          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
