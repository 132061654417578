import HttpService from "./HttpService";

export default class OverpackService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Set overpack
   *
   * @param data
   * @returns {Promise}
   */
  setOverpack(data = {}) {
    return this.httpService.post("/v1/overpacks", data);
  }

  /**
   * Get overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOverpack(id = null) {
    return this.httpService.get("/v1/overpacks/" + id);
  }

  /**
   * Dispatch overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  dispatchOverpack(id = null) {
    return this.httpService.put("/v1/overpack/sent/" + id);
  }

  /**
   * Dispatch multi overpack
   *
   * @param ids
   * @returns {Promise}
   */
  readyToDispatchMultiOverpack(ids = {}) {
    return this.httpService.put("/v1/overpacks/multi/sent", {
      ids: ids,
    });
  }

  dispatchMultiOverpacks(ids = {}) {
    return this.httpService.put("/v1/overpacks/massive_dispatch", {
      ids: ids,
    });
  }

  setToDispatched(id = null) {
    return this.httpService.put(`/v1/overpacks/${id}/dispatch_overpack`);
  }

  /**
   * Get overpacks
   *
   * @param filters
   * @returns {Promise}
   */
  getOverpacks(filters = {}) {
    return this.httpService.get("/v1/overpacks", {
      params: filters,
    });
  }

  /**
   * Generate overpack label
   *
   * @param {*} id
   * @returns {Promise}
   */
  generateOverpackLabel(id) {
    return this.httpService.put("/v1/overpacks/" + id + "/label");
  }

  /**
   * Generate multi overpacks labels
   *
   * @param ids
   * @returns {Promise}
   */
  generateMultiOverpacksLabels(ids = []) {
    return this.httpService.put("/v1/masterboxes/multi/label", {
      ids: ids,
    });
  }

  /**
   * Delete order
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteOverpack(id = null) {
    return this.httpService.delete("/v1/overpacks/" + id);
  }

  /**
   * Get orders without overpacks
   *
   * @param {*} filters
   * @returns {Promise}
   */
  getOrdersWithoutOverpack(filters = {}) {
    return this.httpService.get("/v1/orders-whithout-overpack", {
      params: filters,
    });
  }

  updateOverpack(id = null, data = {}) {
    let orders_id = "{}";
    if (data.orders_id.length > 0) {
      orders_id = "{";
      for (let i = 0, l = data.orders_id.length; i < l; i++) {
        orders_id += data.orders_id[i];
        orders_id += ",";
      }
      orders_id = orders_id.slice(0, -1);
      orders_id += "}";
    }
    data.orders_id = orders_id;
    return this.httpService.put("/v1/overpacks/" + id, data);
  }

  /**
   * Download overpacks
   *
   * @param {*} filters
   * @returns {Promise}
   */
  downloadOverpacks(filters = {}) {
    return this.httpService.get("/v1/overpacks/download", {
      params: filters,
    });
  }

  /**
   * Update First Mile Information
   * @param {*} id
   * @returns {Promise}
   */
  updateFirstMile(id, data = {}) {
    return this.httpService.put(`/v1/overpacks/${id}/update_first_mile`, data);
  }

  /**
   * Get cn23_numbers from Overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getCn23Numbers(id) {
    return this.httpService.get("/v1/overpacks/" + id + "/cn23_numbers");
  }

  /**
   * Update Many First Mile Information
   * @param {*} data
   * @returns {Promise}
   */
  updateManyOverpacks(data) {
    return this.httpService.put(
      "/v1/overpacks/massive_update_first_mile",
      data
    );
  }
}
