<template>
  <div>
    <NbModal :id="id" modalConfig="modal-dialog-centered" width="76rem">
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">{{ overpack.reference }}</h5>
          <NbBadge
            class="fit-content ml-2"
            :type="badgeTypeText.type"
            :text="badgeTypeText.text"
          />
        </div>
      </template>
      <div>
        <div
          v-if="Object.keys(overpack).length > 1"
          class="d-flex justify-content-between mb-5"
        >
          <NbCard
            id="actions-card"
            :title="$t('actions')"
            class="px-3"
            width="35rem"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap pt-3">
                <NbButton
                  v-if="
                    overpack.is_dispatched === false &&
                    overpack.sent_at !== null
                  "
                  class="w-48-5 my-1 px-2 text-left text-14-20"
                  variant="secondary"
                  @click="setToDispatched"
                >
                  {{ $t("overpackViewPage.dispatchOverpack") }}
                </NbButton>
                <NbButton
                  v-if="overpack.sent_at !== null"
                  class="w-48-5 my-1 px-2 text-left text-14-20"
                  variant="secondary"
                  data-toggle="modal"
                  :data-target="`#${id}updateFirstMile`"
                >
                  {{ $t("overpacksListPage.updateFirstMileInformations") }}
                </NbButton>
              </div>
            </template>
          </NbCard>
          <NbCard
            id="recomended-card"
            :title="$t('suggestedAction')"
            class="px-3"
            width="35rem"
          >
            <template #body>
              <div class="pt-3">
                <NbButton
                  v-if="overpack.sent_at === null"
                  class="w-100 my-1 px-2 text-left"
                  title="Dispatch This Order"
                  @click="dispatchOverpack"
                >
                  {{ $t("overpacksListPage.readyToDispatch") }}
                </NbButton>
                <NbButton
                  v-else
                  class="w-100 my-1 px-2 text-left"
                  title="Dispatch This Order"
                  @click="generateOverpackLabel"
                >
                  {{ $t("label") }}
                </NbButton>
              </div>
            </template>
          </NbCard>
        </div>

        <NbCard
          id="shipping-data-card"
          :title="$t('orderViewPage.shipping')"
          class="bg-gray-05 mb-4"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap pt-2">
              <NbTextField
                id="order_number"
                class="w-32 mb-3"
                :name="$t('overpackViewPage.contractName')"
                variant="borderless-gray-10"
              >
                {{ overpack.orders[0].contract_name }}
              </NbTextField>
              <NbTextField
                id="incoterm"
                class="w-32 mb-3"
                :name="$t('orderViewPage.incoterm')"
                variant="borderless-gray-10"
              >
                {{ overpack.orders[0].incoterm }}
              </NbTextField>
              <NbTextField
                id="dispatchDate"
                class="w-32 mb-3"
                :name="$t('overpackViewPage.dispatchDate')"
                variant="borderless-gray-10"
              >
                {{
                  overpack.sent_at != null
                    ? overpack.sent_at.substring(0, 10)
                    : $t("notDispatched")
                }}
              </NbTextField>
              <NbTextField
                id="first_mile_carrier_name"
                class="w-49 mb-3"
                :name="$t('ordersListPage.firstMileCarrier')"
                variant="borderless-gray-10"
              >
                {{
                  overpack.first_mile_carrier_name
                    ? overpack.first_mile_carrier_name
                    : "-"
                }}
              </NbTextField>
              <NbTextField
                id="first_mile_tracking_number"
                class="w-49 mb-3"
                :name="$t('ordersListPage.firstMileTrackingNumber')"
                variant="borderless-gray-10"
              >
                {{
                  overpack.first_mile_tracking_number
                    ? overpack.first_mile_tracking_number
                    : "-"
                }}
              </NbTextField>
              <NbTextField
                id="first_mile_tracking_number"
                class="w-100 mb-0"
                :name="$t('overpackViewPage.shipFrom')"
                variant="borderless-gray-10"
              >
                {{
                  overpack.orders[0].seller.address +
                  ", " +
                  overpack.orders[0].seller.address_number +
                  " - " +
                  overpack.orders[0].seller.city +
                  " / " +
                  overpack.orders[0].seller.country
                }}
              </NbTextField>
            </div>
          </template>
        </NbCard>

        <div class="d-flex justify-content-between mb-4">
          <NbCard
            id="overpack-card"
            :title="$t('overpack')"
            class="bg-gray-05 w-49"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap pt-2">
                <NbTextField
                  id="overpackReference"
                  class="w-49 mb-3"
                  :name="$t('orderViewPage.overpackReference')"
                  variant="borderless-gray-10"
                >
                  {{ overpack.reference }}
                </NbTextField>
                <NbTextField
                  id="countryDestination"
                  class="w-49 mb-3"
                  :name="$t('overpackViewPage.countryDestination')"
                  variant="borderless-gray-10"
                >
                  {{ overpack.orders[0].customer_country }}
                </NbTextField>
                <NbTextField
                  id="totalOrders"
                  class="w-49 mb-3"
                  :name="$t('overpackViewPage.totalOrders')"
                  variant="borderless-gray-10"
                >
                  {{ overpack.orders.length }}
                </NbTextField>
                <NbTextField
                  id="totalVolumes"
                  class="w-49 mb-3"
                  :name="$t('overpackViewPage.totalVolumes')"
                  variant="borderless-gray-10"
                >
                  {{
                    overpack.orders.reduce(
                      (tot, elt) => tot + elt.volumes.length,
                      0
                    )
                  }}
                </NbTextField>
                <NbTextField
                  id="totalOrdersValue"
                  class="w-49 mb-3"
                  :name="$t('overpackViewPage.totalOrdersValue')"
                  variant="borderless-gray-10"
                >
                  {{ currentCurrency }}
                  {{
                    standardizeValue(
                      overpack.orders.reduce(
                        (tot, elt) => tot + parseFloat(elt.total_value),
                        0
                      )
                    )
                  }}
                </NbTextField>
                <NbTextField
                  id="totalWeight"
                  class="w-49 mb-3"
                  :name="$t('overpackViewPage.totalWeight')"
                  variant="borderless-gray-10"
                >
                  {{
                    standardizeValue(
                      overpack.orders.reduce(
                        (tot, elt) => tot + parseFloat(elt.package_weight),
                        0
                      )
                    )
                  }}
                  {{ currentWeightMetric }}
                </NbTextField>
              </div>
            </template>
          </NbCard>

          <NbCard
            id="origin-card"
            :title="$t('overpackViewPage.originHub')"
            class="bg-gray-05 w-49"
          >
            <template #body>
              <div
                v-if="overpack.carrier && overpack.carrier.id"
                class="d-flex justify-content-between flex-wrap pt-2"
              >
                <NbTextField
                  id="carrier-name"
                  class="w-100 mb-3"
                  :name="$t('name')"
                  variant="borderless-gray-10"
                >
                  {{ overpack.carrier.name }}
                </NbTextField>
                <NbTextField
                  id="address"
                  class="w-100 mb-3"
                  :name="$t('address')"
                  variant="borderless-gray-10"
                >
                  {{
                    overpack.carrier.address +
                    ", " +
                    overpack.carrier.address_number +
                    " " +
                    overpack.carrier.address_complement
                  }}
                </NbTextField>
                <NbTextField
                  id="postalCode"
                  class="w-49 mb-3"
                  :name="$t('postalCode')"
                  variant="borderless-gray-10"
                >
                  {{ overpack.carrier.zip_code }}
                </NbTextField>
                <NbTextField
                  id="city"
                  class="w-49 mb-3"
                  :name="$t('city')"
                  variant="borderless-gray-10"
                >
                  {{ overpack.carrier.city }}
                </NbTextField>
                <NbTextField
                  id="state"
                  class="w-49 mb-3"
                  :name="$t('overpackViewPage.state')"
                  variant="borderless-gray-10"
                >
                  {{ overpack.carrier.state }}
                </NbTextField>
                <NbTextField
                  id="country"
                  class="w-49 mb-3"
                  :name="$t('country')"
                  variant="borderless-gray-10"
                >
                  {{ overpack.carrier.country }}
                </NbTextField>
                <NbTextField
                  id="email"
                  class="w-49 mb-3"
                  :name="$t('email')"
                  variant="borderless-gray-10"
                >
                  {{ overpack.carrier.email }}
                </NbTextField>
                <NbTextField
                  id="phone"
                  class="w-49 mb-3"
                  :name="$t('phone')"
                  variant="borderless-gray-10"
                >
                  {{ overpack.carrier.phone }}
                </NbTextField>
              </div>
              <div v-else class="mt-2">
                {{ $t("overpackViewPage.noCarrier") }}
              </div>
            </template>
          </NbCard>
        </div>

        <NbCard
          id="order-card-wraper"
          class="bg-gray-05 mt-4 mb-0"
          :title="$t('overpackViewPage.totalOrders')"
        >
          <template #body>
            <NbTablev2
              tableOf="orderItems"
              :hasParentItens="true"
              ref="orderItems"
              tdClass="td-dark"
              :hiddeTableOptions="true"
              height="fit-content"
              :allFields="allFields"
              :fields="fields"
              @reloadFields="fields = $event"
              :startItems="overpack.orders"
            >
              <template #cell(order_number)="data">
                <div class="link-1" @click="getOrderModal(data.item.id)">
                  {{ data.item.order_number }}
                </div>
              </template>
              <template #cell(length)="data">
                {{ data.item.volumes.length }}
              </template>
              <template #cell(package_weight)="data">
                {{ standardizeValue(data.item.package_weight) }}
                {{ currentWeightMetric }}
              </template>
              <template #cell(actions)="data">
                <NbButton
                  variant="tertiary"
                  icon="trash"
                  data-toggle="modal"
                  data-target="#modalDeleteCustomer"
                  @click="deleteOrder(data.item)"
                >
                  {{ $t("delete") }}
                </NbButton>
              </template>
            </NbTablev2>
            <div
              v-if="overpack.sent_at === null"
              class="d-flex justify-content-end"
              style="height: 2.75rem"
            >
              <div v-if="isEditing" class="w-80">
                <div class="d-flex justify-content-end mb-0">
                  <NbSelectInput
                    id="select-input-orderAdd"
                    class="w-25 mx-1"
                    name=""
                    :options="ordersWithoutOverpackOptions"
                    v-model="orderToAdd"
                  />
                  <NbButton
                    class="mt-1 mr-1"
                    size="sm"
                    icon="plus"
                    variant="tertiary"
                    @click="addOrder()"
                  >
                    {{ $t("overpackViewPage.addOder") }}
                  </NbButton>
                </div>
              </div>
              <NbButton
                class="mt-1 mx-1"
                size="sm"
                icon="fas fa-pen"
                variant="tertiary"
                @click="isEditing = !isEditing"
              >
                {{ $t("edit") }}
              </NbButton>
            </div>
            <div v-if="isEditing">
              <hr />
              <div class="d-flex justify-content-end mb-0">
                <NbButton
                  class="mx-1"
                  size="sm"
                  variant="primary"
                  @click="updateOverpack()"
                >
                  {{ $t("confirm") }}
                </NbButton>
              </div>
            </div>
          </template>
        </NbCard>
      </div>

      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("close") }}
          </NbButton>
        </div>
        <NbFooter
          class="mt-1"
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </template>
    </NbModal>

    <NbModal :id="`${id}updateFirstMile`" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ $t("overpacksListPage.updateFirstMileInformations") }}
          </h5>
        </div>
      </template>
      <template v-slot:body>
        <NbTextInput
          id="firstMileCarrierName"
          class="mb-3"
          required
          :name="$t('overpackViewPage.carrierName')"
          placeholder="Ex: FedEx"
          :error="errorsFirstMile['first_mile_carrier_name']"
          v-model="firstMileInfo.first_mile_carrier_name"
        />
        <NbTextInput
          id="firstMileTrackingNumber"
          required
          :name="$t('trackingNumber')"
          placeholder="Ex: AZ1234567890"
          :error="errorsFirstMile['first_mile_tracking_number']"
          v-model="firstMileInfo.first_mile_tracking_number"
        />
      </template>

      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton class="mx-1" variant="secondary" data-dismiss="modal">
            {{ $t("close") }}
          </NbButton>
          <NbButton class="mx-1" variant="primary" @click="updateFirstMile()">
            {{ $t("update") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <ModalOrderView
      id="overpack-view-order"
      :orderId="currentOrderViewId"
      @reloadOrders="reloadOrders"
    />
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbTextField from "@/components/input/text/NbTextField.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalOrderView from "@/views/orders/components/ModalOrderView.vue";

import OverpackService from "@/services/OverpackService";
import UsersService from "@/services/UsersService";

const overpackService = new OverpackService();
const usersService = new UsersService();

export default {
  components: {
    NbModal,
    NbButton,
    NbBadge,
    NbCard,
    NbTextInput,
    NbTextField,
    NbTablev2,
    NbSelectInput,
    NbFooter,
    ModalOrderView,
  },
  name: "ModalOverpackView",
  props: {
    id: {
      type: String,
      default: "modal-overpack-view",
    },
    overpackId: {
      type: [Number, String],
      required: true,
      overpack: {},
    },
  },
  data() {
    return {
      currentOrderViewId: "",
      fields: [],
      measurementSystem: "metric",
      overpack: {},

      dispatchOverpackIsHidden: false,
      editButtonIsHidden: false,
      updateButtonIsHidden: true,
      isEditing: false,

      editableListIsHidden: true,

      ordersIdList: [],
      ordersToDisplay: [],
      orderToAdd: null,

      ordersWithoutOverpack: [],
      ordersWithoutOverpackOptions: [],
      ordersWithoutOverpackToDisplay: [],
      firstMileInfo: {
        first_mile_carrier_name: null,
        first_mile_tracking_number: null,
      },
      errorsFirstMile: [],
    };
  },
  created() {
    this.fields = this.allFields;
    usersService.getUserMeasurementSystem().then(({ data }) => {
      this.measurementSystem = data.data;
    });
  },
  computed: {
    allFields() {
      let finalOptions = [
        { key: "order_number", label: this.$t("orderNumber") },
        {
          key: "contract_name",
          label: this.$t("overpackViewPage.contractName"),
        },
        {
          key: "length",
          label: this.$t("overpackViewPage.totalVolumes"),
        },
        {
          key: "package_weight",
          label: `
              ${this.$t("overpackViewPage.totalWeight")}
              (${this.currentWeightMetric})
            `,
        },
      ];
      if (this.isEditing) {
        finalOptions.push({ key: "actions", label: this.$t("actions") });
      }
      return finalOptions;
    },
    badgeTypeText() {
      if (this.overpack.sent_at === null) {
        return { type: "success", text: this.$t("new") };
      }
      if (this.overpack.is_dispatched === false) {
        return {
          type: "warning",
          text: this.$t("overpacksListPage.readyToDispatch"),
        };
      }
      return { type: "success", text: this.$t("dispatched") };
    },
    currentWeightMetric() {
      if (this.measurementSystem === "metric") {
        return "kg";
      }
      return "lb";
    },
    currentCurrency() {
      if (
        this.overpack &&
        this.overpack.orders &&
        this.overpack.orders[0].currency == "USD"
      ) {
        return "$";
      }
      return "R$";
    },
    currentMeasurementMetric() {
      if (this.measurementSystem === "metric") {
        return "cm";
      }
      return "in";
    },
  },
  methods: {
    getOrderModal(id) {
      if (id) {
        this.currentOrderViewId = id;
        this.$helpers.openModal("overpack-view-order");
        return;
      }
    },
    reloadOrders() {
      this.$refs?.tableOrders?.getData();
    },
    updateOverpack() {
      this.overpack.orders_id = this.ordersIdList;

      overpackService
        .updateOverpack(this.overpack.id, this.overpack)
        .then(() => {
          this.getData();
          this.$emit("reloadOverpacks");
          this.$helpers.toast("Overpack updated", "success");
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }
          let errors = this.$helpers.extractErrorMessages(error);
          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },
    addOrder() {
      const orderToAdd = this.orderToAdd;
      if (orderToAdd !== null) {
        this.overpack.orders.push(orderToAdd);
        let ordersIdList = this.ordersIdList;
        ordersIdList.push(orderToAdd.id);
        const index = this.ordersWithoutOverpackOptions.findIndex(
          (option) => option.text === orderToAdd.order_number
        );
        if (index > -1) {
          this.ordersWithoutOverpackOptions.splice(index, 1);
        }
        this.orderToAdd = null;
      }
    },
    deleteOrder(order) {
      this.overpack.orders.splice(order.index, 1);
      let ordersIdList = this.ordersIdList;
      const index_id = ordersIdList.indexOf(order.id);
      if (index_id > -1) {
        ordersIdList.splice(index_id, 1);
      }
      const deletedOrder = { text: order.order_number, value: order };
      this.ordersWithoutOverpackOptions.push(deletedOrder);
    },
    getTotalWeight(orders) {
      if (orders) {
        let totalWeight = orders.reduce((acc, order) => {
          return (
            acc +
            order.volumes.reduce((sum, volume) => {
              return sum + parseFloat(volume.weight);
            }, 0)
          );
        }, 0);

        return totalWeight.toFixed(3);
      }
      return "Not Found";
    },
    updateFirstMile() {
      this.errorsFirstMile = [];
      overpackService
        .updateFirstMile(this.overpack.id, this.firstMileInfo)
        .then(() => {
          this.$helpers.closeModal(this.id + "updateFirstMile");
          this.$helpers.toast("First mile information updated", "success");
          this.getData();
        })
        .catch(({ response }) => {
          if (response.data.messages[0]) {
            this.errorsFirstMile = response.data.messages[0];
          }
        });
    },
    generateOverpackLabel() {
      overpackService
        .generateOverpackLabel(this.overpack.id)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },
    dispatchOverpack() {
      overpackService
        .dispatchOverpack(this.overpack.id)
        .then(() => {
          this.$router.push("/overpacks").then(() => {
            this.$helpers.toast("Overpack ready to dispatch", "success");
          });
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },
    setToDispatched() {
      overpackService
        .setToDispatched(this.overpack.id)
        .then(() => {
          this.getData();
        })
        .catch(({ response }) => {
          if (response.data.messages[0]) {
            this.$helpers.toast(response.data.messages[0], "danger");
          }
        });
    },
    getData() {
      overpackService
        .getOverpack(this.overpackId)
        .then((response) => {
          const overpack = response.data.data;
          this.overpack = overpack;
          let firstOrder = this.overpack.orders[0];
          let incoterm = firstOrder.incoterm;
          let contract_id = firstOrder.contract_id;
          let customer_country = firstOrder.customer_country;
          let currency = firstOrder.currency;
          this.overpack.orders_id.forEach((order_id) => {
            this.ordersIdList.push(order_id);
          });
          let filters = {
            incoterm: incoterm,
            contract_id: contract_id,
            customer_country: customer_country,
            currency: currency,
            is_mini: firstOrder.is_mini,
          };
          overpackService.getOrdersWithoutOverpack(filters).then((response) => {
            this.ordersWithoutOverpack = response.data.data;
            this.ordersWithoutOverpackOptions = this.ordersWithoutOverpack.map(
              (order) => ({
                value: order,
                text: order.order_number,
              })
            );
            this.loaded = false;
          });
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$router.push("/");
          }

          this.$helpers.toast(error.message, "danger");
        });
    },
    standardizeValue(val) {
      if (val && (typeof val === "number" || typeof val === "string")) {
        return parseFloat(val).toFixed(2);
      }
      return "Not Found";
    },
  },
  watch: {
    overpackId() {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
