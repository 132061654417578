<template>
  <div>
    <span :class="['status', overpackStatus.status]">{{
      overpackStatus.label
    }}</span>
  </div>
</template>

<script>
export default {
  name: "OverpackBadgeStatus",
  props: {
    overpack: {
      type: Object,
      required: true,
    },
  },
  computed: {
    overpackStatus() {
      if (
        this.overpack.sent_at !== null &&
        this.overpack.is_dispatched === false
      ) {
        return {
          status: "readyToDispatch",
          label: this.$t("overpacksListPage.readyToDispatch"),
        };
      }

      if (this.overpack.is_dispatched === true) {
        return {
          status: "dispatched",
          label: this.$t("dispatched"),
        };
      }

      return {
        status: "new",
        label: this.$t("new"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid;
  background-color: var(--attention-10);
  border-color: #c8820033;
  &.new {
    background-color: var(--success-10);
    border-color: #00960033;
  }
  &.readyToDispatch {
    background-color: #0174d81d;
    border-color: var(--blue);
  }
  &.dispatched {
    background-color: var(--success-10);
    border-color: #00960033;
  }
}
</style>
