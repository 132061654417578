<template>
  <!-- not used -->
  <LoadingPage v-if="loaded" />
  <div v-else>
    <div class="pr-5 pb-2">
      <back-button />
    </div>
    <h5 class="font-weight-light mb-5">
      {{ $t("overpackViewPage.title") }}:
      <span class="font-weight-bold">{{ overpack.reference }}</span>

      <span
        v-if="overpack.sent_at === null"
        class="ml-1 text-uppercase badge badge-success"
        >{{ $t("new") }}</span
      >
      <span
        v-else-if="overpack.is_dispatched === false"
        class="ml-1 text-uppercase badge badge-info"
        >{{ $t("overpacksListPage.readyToDispatch") }}</span
      >
      <span v-else class="ml-1 text-uppercase badge badge-success">{{
        $t("dispatched")
      }}</span>

      <button
        v-if="overpack.is_dispatched === false && overpack.sent_at !== null"
        class="button-primary float-right my-1 ml-2"
        v-on:click="setToDispatched"
        title="Ready to dispatch"
      >
        <i class="fas fa-chevron-circle-right mr-1"></i
        >{{ $t("overpackViewPage.dispatchOverpack") }}
      </button>

      <a
        v-if="overpack.sent_at === null"
        class="button-primary float-right my-1"
        v-on:click="dispatchOverpack"
        title="Ready to dispatch"
      >
        <i class="fas fa-chevron-circle-right mr-1"></i
        >{{ $t("overpacksListPage.readyToDispatch") }}
      </a>
      <a
        v-else
        class="button-secondary float-right my-1"
        v-on:click="generateOverpackLabel"
        title="Overpack Label"
      >
        <i class="far fa-file-alt fa-lg mr-2"></i>{{ $t("label") }}
      </a>

      <button
        v-if="overpack.sent_at !== null"
        class="button-primary float-right my-1 mr-2"
        data-toggle="modal"
        data-target="#updateFirstMile"
      >
        <i class="fas fa-truck mr-1"></i
        >{{ $t("overpacksListPage.updateFirstMileInformations") }}
      </button>
    </h5>

    <div class="card-deck">
      <!-- overpack -->
      <div class="card bg-light border-white rounded">
        <div class="pb-2 pt-4 px-3">
          <h5 class="pl-3">{{ $t("overpack") }}</h5>
          <hr class="mb-0" />
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("orderViewPage.overpackReference") }}:</strong>
          </div>
          <div class="col align-middle">{{ overpack.reference }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col-7">
            <strong>{{ $t("overpackViewPage.countryDestination") }}:</strong>
          </div>
          <div class="col">{{ overpack.orders[0].customer_country }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col-7">
            <strong>{{ $t("overpackViewPage.totalOrders") }}:</strong>
          </div>
          <div class="col align-middle">{{ overpack.orders.length }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col-7">
            <strong>{{ $t("overpackViewPage.totalVolumes") }}:</strong>
          </div>
          <div class="col align-middle">
            {{
              overpack.orders.reduce((tot, elt) => tot + elt.volumes.length, 0)
            }}
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col-7">
            <strong>{{ $t("overpackViewPage.totalOrdersValue") }}:</strong>
          </div>
          <div class="col align-middle">
            {{ overpack.orders[0].currency }}
            {{
              standardizeValue(
                overpack.orders.reduce(
                  (tot, elt) => tot + parseFloat(elt.total_value),
                  0
                )
              )
            }}
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col-7">
            <strong>{{ $t("overpackViewPage.totalWeight") }}:</strong>
          </div>
          <div class="col align-middle">
            {{
              standardizeValue(
                overpack.orders.reduce(
                  (tot, elt) => tot + parseFloat(elt.package_weight),
                  0
                )
              )
            }}
            {{ currentWeightMetric }}
          </div>
        </div>
      </div>

      <!-- overpack -->

      <!-- shipping -->
      <div class="card bg-light border-white rounded">
        <div class="pb-2 pt-4 px-3">
          <h5 class="pl-3">{{ $t("orderViewPage.shipping") }}</h5>
          <hr class="mb-0" />
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("overpackViewPage.contractName") }}:</strong>
          </div>
          <div class="col">{{ overpack.orders[0].contract_name }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("orderViewPage.incoterm") }}:</strong>
          </div>
          <div class="col">{{ overpack.orders[0].incoterm }}</div>
        </div>
        <div
          v-if="
            overpack.first_mile_tracking_number !== null ||
            overpack.sent_at !== null
          "
        >
          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("ordersListPage.firstMileCarrier") }}:</strong>
            </div>
            <div class="col">{{ overpack.first_mile_carrier_name }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong
                >{{ $t("ordersListPage.firstMileTrackingNumber") }}:</strong
              >
            </div>
            <div class="col">{{ overpack.first_mile_tracking_number }}</div>
          </div>
        </div>

        <div
          v-if="overpack.sent_at != null"
          class="row border rounded bg-white p-2 mx-3 mb-2"
        >
          <div class="col">
            <strong>{{ $t("overpackViewPage.dispatchDate") }}:</strong>
          </div>
          <div class="col">{{ overpack.sent_at.substring(0, 10) }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{ $t("overpackViewPage.shipFrom") }}:</strong>
          </div>
          <div class="col">
            {{
              overpack.orders[0].seller.address +
              ", " +
              overpack.orders[0].seller.address_number +
              " - " +
              overpack.orders[0].seller.city +
              " / " +
              overpack.orders[0].seller.country
            }}
          </div>
        </div>
      </div>
      <!-- shipping -->

      <!-- destination -->
      <div class="card bg-light border-white rounded">
        <div class="pb-2 pt-4 px-3">
          <h5 class="pl-3">{{ $t("overpackViewPage.originHub") }}</h5>
          <hr class="mb-0" />
        </div>
        <div v-if="overpack.carrier && overpack.carrier.id">
          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("name") }}:</strong>
            </div>
            <div class="col">{{ overpack.carrier.name }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("address") }}:</strong>
            </div>
            <div class="col">
              {{
                overpack.carrier.address +
                ", " +
                overpack.carrier.address_number +
                " " +
                overpack.carrier.address_complement
              }}
            </div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("postalCode") }}:</strong>
            </div>
            <div class="col">{{ overpack.carrier.zip_code }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("city") }}:</strong>
            </div>
            <div class="col">{{ overpack.carrier.city }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("state") }}:</strong>
            </div>
            <div class="col">{{ overpack.carrier.state }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("country") }}:</strong>
            </div>
            <div class="col">{{ overpack.carrier.country }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("email") }}:</strong>
            </div>
            <div class="col">{{ overpack.carrier.email }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("phone") }}:</strong>
            </div>
            <div class="col">{{ overpack.carrier.phone }}</div>
          </div>
        </div>
        <div v-else>
          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              {{ $t("overpackViewPage.noCarrier") }}
            </div>
          </div>
        </div>
      </div>
      <!-- destination -->
    </div>

    <div class="accordion my-4" id="accordionShowOverpack">
      <div class="card">
        <a
          class="card-header accordion-toggle-white collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#collapseShowOverpackList"
          aria-expanded="false"
          aria-controls="collapseShowOverpackList"
          id="headingShowOverpackList"
        >
          <h5 v-if="editableListIsHidden" class="mb-0 mr-5">
            {{ $t("overpackViewPage.totalOrders") }}:
            <strong>{{ overpack.orders.length }}</strong>
            <i class="fa fa-chevron-down pull-right"></i>
          </h5>

          <h5 v-if="!editableListIsHidden" class="mb-0 mr-5">
            {{ $t("overpackViewPage.totalOrders") }}:
            <strong>{{ ordersToDisplay.length }}</strong>
            <i class="fa fa-chevron-down pull-right"></i>
          </h5>
        </a>
        <div
          id="collapseShowOverpackList"
          class="collapse"
          aria-labelledby="headingShowOverpackList"
          data-parent="#accordionShowOverpack"
        >
          <div class="card-body py-3 pl-5">
            <table
              v-if="editableListIsHidden"
              class="table table-borderless rounded"
            >
              <thead>
                <tr>
                  <th class="text-center">{{ $t("orderNumber") }}</th>
                  <th class="text-center">
                    {{ $t("overpackViewPage.contractName") }}
                  </th>
                  <th class="text-center">
                    {{ $t("overpackViewPage.totalVolumes") }}
                  </th>
                  <th class="text-center">
                    {{ $t("overpackViewPage.totalWeight") }} ({{
                      currentWeightMetric
                    }})
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order, index) in overpack.orders" :key="index">
                  <td class="text-center">
                    <a :href="'/orders/' + order.id">{{
                      order.order_number
                    }}</a>
                  </td>
                  <td class="text-center">{{ order.contract_name }}</td>
                  <td class="text-center">{{ order.volumes.length }}</td>
                  <td class="text-center">
                    {{ standardizeValue(order.package_weight) }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="!editableListIsHidden">
              <table class="table table-borderless rounded">
                <thead>
                  <tr>
                    <th class="text-center">{{ $t("orderNumber") }}</th>
                    <th class="text-center">
                      {{ $t("overpackViewPage.contractName") }}
                    </th>
                    <th class="text-center">
                      {{ $t("overpackViewPage.totalVolumes") }}
                    </th>
                    <th class="text-center">
                      {{ $t("overpackViewPage.totalWeight") }} ({{
                        currentWeightMetric
                      }})
                    </th>
                    <th class="text-center">
                      {{ $t("orderViewPage.deleteOrder") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(order, index) in ordersToDisplay" :key="index">
                    <td class="text-center">{{ order.order_number }}</td>
                    <td class="text-center">{{ order.contract_name }}</td>
                    <td class="text-center">
                      {{ standardizeValue(order.volumes.length) }}
                    </td>
                    <td class="text-center">
                      {{ standardizeValue(order.package_weight) }}
                    </td>
                    <td class="text-center">
                      <button
                        class="button-link p-0 m-0"
                        v-on:click="deleteOrder(order, index)"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-if="ordersWithoutOverpackToDisplay.length > 0">
                <form v-on:submit.prevent="addOrder()" class="form-inline mt-4">
                  <select
                    id="orders_without_overpack_list"
                    class="form-control"
                    v-model="orderToAdd"
                  >
                    <option>{{ $t("orderNumber") }}</option>
                    <option
                      v-for="order_without_overpack in ordersWithoutOverpackToDisplay"
                      :key="order_without_overpack.id"
                      :value="order_without_overpack"
                    >
                      {{ order_without_overpack.order_number }}
                    </option>
                  </select>

                  <button type="submit" class="button-success ml-3" href="#">
                    {{ $t("overpackViewPage.addOder") }}
                  </button>
                </form>
              </div>

              <div v-else>
                <p>{{ $t("overpackViewPage.noOrderToOverpack") }}</p>
              </div>
            </div>

            <div v-if="overpack.sent_at === null" class="mb-4 mt-4">
              <button
                v-if="!editButtonIsHidden"
                v-on:click="editOverpack()"
                class="button-primary float-right ml-3 mb-4 text-white"
              >
                {{ $t("edit") }}
              </button>

              <button
                v-if="!updateButtonIsHidden"
                v-on:click="updateOverpack()"
                class="button-primary float-right ml-3 mb-4 text-white"
              >
                {{ $t("update") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="overpack.sent_at === null" class="mb-4 mt-4 pb-4">
      <button
        data-toggle="modal"
        data-target="#modalDeleteOverpack"
        class="button-danger float-right ml-3 mb-4 text-white"
      >
        {{ $t("delete") }}
      </button>
    </div>

    <div class="modal fade" id="modalDeleteOverpack">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalDeleteOverpackTitle">
              {{ $t("confirmDelete", { val: "this overpack" }) }}?
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <a
              href="#"
              class="button-danger float-right ml-3"
              v-on:click="deleteOverpack"
              data-dismiss="modal"
              >{{ $t("delete") }}</a
            >
            <a
              href="#"
              class="button-light float-right ml-3"
              data-dismiss="modal"
              >{{ $t("cancel") }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="updateFirstMile"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Delete"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="Update">
              {{ $t("overpacksListPage.updateFirstMileInformations") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name"
                    >{{ $t("overpackViewPage.carrierName") }}*</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="firstMileCarrierName"
                    v-model="firstMileInfo.first_mile_carrier_name"
                    :class="{
                      'is-invalid': errorsFirstMile['first_mile_carrier_name'],
                    }"
                    placeholder="Ex: FedEx"
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errorsFirstMile[
                      'first_mile_carrier_name'
                    ]"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name">{{ $t("trackingNumber") }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    id="firstMileTrackingNumber"
                    v-model="firstMileInfo.first_mile_tracking_number"
                    :class="{
                      'is-invalid':
                        errorsFirstMile['first_mile_tracking_number'],
                    }"
                    placeholder="Ex: AZ1234567890"
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errorsFirstMile[
                      'first_mile_tracking_number'
                    ]"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="button-danger mr-2"
              id="update-first-mile-cancel-button"
              data-dismiss="modal"
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="button-success"
              v-on:click="updateFirstMile(overpack)"
            >
              {{ $t("update") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL -->
</template>

<script>
import BackButton from "../../components/generic/BackButton";
import OverpackService from "../../services/OverpackService";
import UsersService from "../../services/UsersService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const overpackService = new OverpackService();
const usersService = new UsersService();

export default {
  name: "OverpacksView",

  components: { BackButton, LoadingPage },

  data: () => {
    return {
      loaded: true,
      measurementSystem: "metric",
      overpack: {},

      dispatchOverpackIsHidden: false,
      editButtonIsHidden: false,
      updateButtonIsHidden: true,

      editableListIsHidden: true,

      ordersIdList: [],
      ordersToDisplay: [],
      orderToAdd: "",

      ordersWithoutOverpack: [],
      ordersWithoutOverpackToDisplay: [],
      firstMileInfo: {
        first_mile_carrier_name: null,
        first_mile_tracking_number: null,
      },
      errorsFirstMile: [],
    };
  },
  computed: {
    currentWeightMetric() {
      if (this.measurementSystem === "metric") {
        return "kg";
      }
      return "lb";
    },
    currentMeasurementMetric() {
      if (this.measurementSystem === "metric") {
        return "cm";
      }
      return "in";
    },
  },
  beforeMount() {
    usersService.getUserMeasurementSystem().then(({ data }) => {
      this.measurementSystem = data.data;
    });

    overpackService
      .getOverpack(this.$route.params.id)
      .then((response) => {
        const overpack = response.data.data;
        this.overpack = overpack;
        let firstOrder = this.overpack.orders[0];
        let incoterm = firstOrder.incoterm;
        let contract_id = firstOrder.contract_id;
        let customer_country = firstOrder.customer_country;
        let currency = firstOrder.currency;
        let filters = {
          incoterm: incoterm,
          contract_id: contract_id,
          customer_country: customer_country,
          currency: currency,
          is_mini: firstOrder.is_mini,
        };
        overpackService.getOrdersWithoutOverpack(filters).then((response) => {
          this.ordersWithoutOverpack = response.data.data;
          this.loaded = false;
        });
      })
      .catch((error) => {
        if (error.response.status == 404) {
          this.$router.push("/");
        }

        this.$helpers.toast(error.message, "danger");
      });
  },
  methods: {
    editOverpack() {
      this.orderToAdd = "Order Number";
      this.dispatchOverpackIsHidden = true;
      this.editButtonIsHidden = true;
      this.updateButtonIsHidden = false;
      this.editableListIsHidden = false;
      let ordersIdList = this.ordersIdList;
      let ordersToDisplay = this.ordersToDisplay;
      let ordersWithoutOverpackToDisplay = this.ordersWithoutOverpackToDisplay;

      this.overpack.orders_id.forEach(function (order_id) {
        ordersIdList.push(order_id);
      });

      this.overpack.orders.forEach(function (order) {
        ordersToDisplay.push(order);
      });

      this.ordersWithoutOverpack.forEach(function (order_without_overpack) {
        ordersWithoutOverpackToDisplay.push(order_without_overpack);
      });
    },

    addOrder() {
      let orderToAdd = this.orderToAdd;
      if (orderToAdd !== "Order Number") {
        let ordersToDisplay = this.ordersToDisplay;
        ordersToDisplay.push(orderToAdd);
        let ordersIdList = this.ordersIdList;
        ordersIdList.push(orderToAdd.id);

        let ordersWithoutOverpackToDisplay =
          this.ordersWithoutOverpackToDisplay;
        const index = ordersWithoutOverpackToDisplay.indexOf(orderToAdd);
        if (index > -1) {
          ordersWithoutOverpackToDisplay.splice(index, 1);
        }
        this.orderToAdd = "Order Number";
      }
    },

    deleteOrder(order, index) {
      this.ordersToDisplay.splice(index, 1);
      let ordersIdList = this.ordersIdList;
      const index_id = ordersIdList.indexOf(order.id);
      if (index_id > -1) {
        ordersIdList.splice(index_id, 1);
      }
      let ordersWithoutOverpackToDisplay = this.ordersWithoutOverpackToDisplay;
      ordersWithoutOverpackToDisplay.push(order);
    },

    updateOverpack() {
      this.overpack.orders_id = this.ordersIdList;

      overpackService
        .updateOverpack(this.overpack.id, this.overpack)
        .then(() => {
          this.$router.push("/overpacks/").then(() => {
            this.$helpers.toast("Overpack updated", "success");
          });
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }
          let errors = this.$helpers.extractErrorMessages(error);
          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          window.scrollTo(0, 0);
        });
    },
    setToDispatched() {
      overpackService
        .setToDispatched(this.overpack.id)
        .then(() => {
          window.location.reload();
        })
        .catch(({ response }) => {
          if (response.data.messages[0]) {
            this.$helpers.toast(response.data.messages[0], "danger");
          }
        });
    },
    dispatchOverpack() {
      overpackService
        .dispatchOverpack(this.overpack.id)
        .then(() => {
          this.$router.push("/overpacks").then(() => {
            this.$helpers.toast("Overpack ready to dispatch", "success");
          });
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },
    generateOverpackLabel() {
      overpackService
        .generateOverpackLabel(this.overpack.id)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },
    deleteOverpack() {
      overpackService
        .deleteOverpack(this.overpack.id)
        .then(() => {
          this.$router.push("/overpacks").then(() => {
            this.$helpers.toast("Overpack deleted", "success");
          });
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },
    updateFirstMile() {
      this.errorsFirstMile = [];
      overpackService
        .updateFirstMile(this.overpack.id, this.firstMileInfo)
        .then(() => {
          document.getElementById("update-first-mile-cancel-button").click();
          window.location
            .reload()
            .finally(() =>
              this.$helpers.toast("First mile information updated", "success")
            );
        })
        .catch(({ response }) => {
          if (response.data.messages[0]) {
            this.errorsFirstMile = response.data.messages[0];
          }
        });
    },
    standardizeValue(val) {
      return parseFloat(val).toFixed(2);
    },
  },
};
</script>
