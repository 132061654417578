<template>
  <div>
    <NbPageTitle :title="$t('overpacksListPage.title')" />

    <ModalUpdateFirstMile
      :show.sync="modalUpdateFirstMile"
      :ids="selectedOverpacks"
    />
    <NbModal id="modalDeleteMultipleOverpacks">
      <template #body>
        <h3 class="heading-3">
          {{
            $t("confirmDelete", {
              val:
                selectedOverpacks.length > 1
                  ? `${$t("these")} (${selectedOverpacks.length}) ${$tc(
                      "overpacksListPage.found",
                      selectedOverpacks.length
                    )}`
                  : `${$t("this")} (${selectedOverpacks.length}) ${$tc(
                      "overpacksListPage.found",
                      selectedOverpacks.length
                    )}`,
            })
          }}?
        </h3>
      </template>

      <template #footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            >{{ $t("cancel") }}</NbButton
          >
          <NbButton class="ml-2" @click="deleteMultipleOverpacks">{{
            $t("delete")
          }}</NbButton>
        </div>
      </template>
    </NbModal>

    <NbTablev2
      ref="overpacksTable"
      tableOf="overpacks"
      namespace="overpacks"
      :fields="fields"
      :all-fields="tableFields"
      @reloadFields="fields = $event"
      @optionTook="optionTook($event)"
      :filterOptions="filterOptions"
      selectable
      vistaBtn
      :buttonOptions="tableActions"
      :optionsWidth="260"
      :selectedItems.sync="selectedOverpacks"
    >
      <template #cell(reference)="data">
        <div class="link-1" @click="getOverpackModal(data.item.id)">
          {{ data.item.reference }}
        </div>
      </template>

      <template #cell(customer_country)="data">
        {{ data.item.orders[0].customer_country }}
      </template>

      <template #cell(created_at)="data">
        {{ data.item.created_at.substring(0, 10) }}
      </template>

      <template #cell(order_number)="data">
        <span class="dropdown">
          <a data-toggle="dropdown" class="text-link">
            <u
              >{{ data.item.orders.length }}
              {{ data.item.orders.length > 1 ? $t("orders") : $t("order") }}
            </u>
          </a>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <div
              v-for="(order, orderIndex) in data.item.orders"
              :key="orderIndex"
              class="link-1 dropdown-item text-center"
              @click="getOrderModal(order.id)"
            >
              {{ order.order_number }}
            </div>
          </div>
        </span>
      </template>

      <template #cell(is_dispatched)="data">
        <OverpackBadgeStatus :overpack="data.item" />
      </template>

      <template #cell(sent_at)="data">
        <BtnGenerateLabelAndInvoice
          v-if="data.item.sent_at != null"
          :id="data.item.id"
        />

        <BtnDispatchOverpack
          v-else
          @success="$refs.overpacktable?.getData()"
          :id="data.item.id"
        />
      </template>

      <template #buttons-start>
        <div>
          <button
            class="button-light mx-1"
            data-toggle="modal"
            data-target="#download-modal"
          >
            Download overpacks
          </button>
        </div>
      </template>
    </NbTablev2>

    <!-- modals -->
    <ModalOverpackView
      :overpackId="currentOverpackViewId"
      @reloadOverpacks="reloadOverpacks"
    />
    <ModalOrderView
      :orderId="currentOrderViewId"
      @reloadOrders="reloadOrders"
    />
    <!-- end modals -->
  </div>
</template>

<script>
import NProgress from "nprogress";
import NbPageTitle from "../../components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbModal from "@/components/modal/NbModal.vue";
import ModalOverpackView from "@/views/overpacks/components/ModalOverpackView.vue";
import ModalOrderView from "@/views/orders/components/ModalOrderView.vue";

import DownloadsService from "../../services/DownloadsService";
import OverpackService from "@/services/OverpackService";
import OverpackBadgeStatus from "./components/OverpackBadgeStatus.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import ModalUpdateFirstMile from "./components/ModalUpdateFirstMile.vue";
import BtnDispatchOverpack from "./components/BtnDispatchOverpack.vue";
import BtnGenerateLabelAndInvoice from "./components/BtnGenerateLabelAndInvoice.vue";

const overpackService = new OverpackService();
const downloadsService = new DownloadsService();

export default {
  name: "AppOverpacks",
  components: {
    NbPageTitle,
    NbTablev2,
    OverpackBadgeStatus,
    NbButton,
    NbModal,
    ModalOverpackView,
    ModalUpdateFirstMile,
    BtnDispatchOverpack,
    BtnGenerateLabelAndInvoice,
    ModalOrderView,
  },
  data() {
    return {
      currentOverpackViewId: "",
      currentOrderViewId: "",
      fields: [],
      selectedOverpacks: [],
      modalUpdateFirstMile: false,
    };
  },
  computed: {
    tableActions() {
      return [
        {
          text: this.$t("overpacksListPage.updateFirstMileInformations"),
          value: "triggerModalUpdateMultipleOverpacks",
        },
        {
          text: this.$t("overpacksListPage.deleteOverpacks"),
          value: "triggerModalDeleteMultipleOverpacks",
        },

        {
          text: `${this.$t("overpacksListPage.setOverpackAs")} ${this.$t(
            "overpacksListPage.readyToDispatch"
          )}`,
          value: "readyToDispatchMultiOverpack",
        },
        {
          text: this.$t("overpacksListPage.dispatchOverpack"),
          value: "massiveDispatchOverpack",
        },
        {
          text: this.$t("overpacksListPage.generateLabel"),
          value: "generateMultipleLabels",
        },
      ];
    },
    allStatus() {
      return [
        { value: null, text: "All" },
        { value: 1, text: this.$t("new") },
        { value: 2, text: this.$t("overpacksListPage.readyToDispatch") },
        { value: 3, text: this.$t("dispatched") },
      ];
    },
    filterOptions() {
      return [
        { key: "reference", label: this.$t("trackingNumber") },
        {
          key: "current_status",
          label: this.$t("status"),
          type: "select",
          options: this.allStatus,
        },
        {
          key: "registration_date",
          label: this.$t("betweenDates"),
          type: "dates",
        },
      ];
    },
    tableFields() {
      return [
        {
          key: "reference",
          label: this.$t("overpacksListPage.trackingNumber"),
        },
        { key: "customer_country", label: this.$t("country") },
        { key: "created_at", label: this.$t("creationDate") },
        { key: "order_number", label: this.$t("orders") },
        { key: "is_dispatched", label: this.$t("status") },
        { key: "sent_at", label: this.$t("label") },
      ];
    },
  },
  methods: {
    getOrderModal(id) {
      if (id) {
        this.currentOrderViewId = id;
        this.$helpers.openModal("modal-order-view");
        return;
      }
    },
    reloadOrders() {
      this.$refs?.tableOrders?.getData();
    },
    reloadOverpacks() {
      this.$refs?.overpacksTable?.getData();
    },
    getOverpackModal(id) {
      if (id) {
        this.currentOverpackViewId = id;
        this.$helpers.openModal("modal-overpack-view");
        return;
      }
    },
    optionTook(event) {
      this[event]();
    },
    triggerModalUpdateMultipleOverpacks() {
      this.modalUpdateFirstMile = true;
    },
    triggerModalDeleteMultipleOverpacks() {
      this.$helpers.openModal("modalDeleteMultipleOverpacks");
    },

    initiateFileCreation(dataQuery) {
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$router.push("/archives");
      });
    },
    downloadOverpacks() {
      this.initiateFileCreation({
        data_type: "overpacks",
        reference_ids: this.selectedOverpacks,
      });
    },
    updateMultipleOverpacks() {
      const overpacksToUpdate = this.selectedOverpacks;
      this.errors = [];

      overpackService
        .updateManyOverpacks({
          overpack_ids: overpacksToUpdate,
          first_mile_carrier_name: this.firstMileInfo.first_mile_carrier_name,
          first_mile_tracking_number:
            this.firstMileInfo.first_mile_tracking_number,
        })
        .then((response) => {
          const message = response.data.messages[0];
          this.$helpers.toast(message, "success");
          document.getElementById("update-first-mile-cancel-button").click();
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.errors = error.response.data.messages[0];
          }

          NProgress.done();
        });
    },
    deleteMultipleOverpacks() {
      const overpacksToDelete = this.selectedOverpacks;
      let someOverpacksAlreadyDispatched = false;
      this.overpacks.forEach((overpack) => {
        if (
          !someOverpacksAlreadyDispatched &&
          this.selectedOverpacks.includes(overpack.id) &&
          overpack.sent_at
        ) {
          this.$helpers.toast(
            `${this.$t("overpacksListPage.OverpackHasAlreadyDispatched")}`,
            "danger"
          );
          someOverpacksAlreadyDispatched = true;
        }
      });
      if (!someOverpacksAlreadyDispatched) {
        let deleteResult = async (overpacksToBeDeleted) => {
          var result;
          await overpacksToBeDeleted.forEach(async (overpack_id) => {
            result = await overpackService.deleteOverpack(overpack_id);
            for (var i = 0; i < this.overpacks.length; i++) {
              if (this.overpacks[i].id == overpack_id) {
                this.overpacks.splice(i, 1);
              }
            }
          });
          return result;
        };
        deleteResult(overpacksToDelete)
          .then(() => {
            this.$helpers.toast(
              `${this.$t("overpacks")} ${this.$t("deleted")}`,
              "success"
            );
          })
          .catch((error) => {
            if (
              error.hasOwnProperty("response") &&
              error.response.hasOwnProperty("data") &&
              error.response.data.hasOwnProperty("messages")
            ) {
              this.errors = error.response.data.messages[0];
            }

            let errors = this.$helpers.extractErrorMessages(error);

            errors.forEach((message) => {
              this.$helpers.toast(message, "danger");
            });

            NProgress.done();
          });
      }
    },
    readyToDispatchMultiOverpack() {
      const ids = this.selectedOverpacks;
      overpackService
        .readyToDispatchMultiOverpack(ids)
        .then(() => {
          this.$helpers.toast(
            `Overpack ${this.$t("overpacksListPage.readyToDispatch")}`,
            "success"
          );
        })
        .then(() => {
          overpackService.getOverpacks().then((response) => {
            this.overpacks = response.data.data.elements;
          });
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.errors = error.response.data.messages[0];
          }
          let errors = this.$helpers.extractErrorMessages(error);
          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },
    massiveDispatchOverpack() {
      const ids = this.selectedOverpacks;
      overpackService
        .dispatchMultiOverpacks(ids)
        .then((response) => {
          const message = response.data.messages[0];
          this.$helpers.toast(message, "success");
        })
        .then(() => {
          overpackService.getOverpacks().then((response) => {
            this.overpacks = response.data.data.elements;
          });
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.errors = error.response.data.messages[0];
          }
          let errors = this.$helpers.extractErrorMessages(error);
          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },
    generateMultipleLabels() {
      let generateLabel = true;
      this.overpacks.forEach((overpack) => {
        if (
          this.selectedOverpacks.includes(overpack.id) &&
          !overpack.sent_at &&
          generateLabel
        ) {
          generateLabel = false;
          this.$helpers.toast(
            `${this.$t("overpacksListPage.overpackHasNotDispatched")}`,
            "danger"
          );
        }
      });
      if (generateLabel) this.generateMultiOverpacksLabels();
    },
    generateMultiOverpacksLabels() {
      let ids = this.selectedOverpacks;
      overpackService
        .generateMultiOverpacksLabels(ids)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);
          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
          NProgress.done();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
